//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  name: "getUserType.vue",
  data () {
    // 比较两次密码是否相同
    const comparePasswdValidator = (rule, value, callback) =>{
      // 获取获取值的方法
      let getvaluesMethod = rule.getValuesMethod;
      // 调用getvaluesMethod方法，获取对象值
      let formData = getvaluesMethod();

      // 有一个为空，可能还没有输入值，此时不比较
      if (!formData.memberType || !formData.subMemberType){
        return callback();
      }
      // 比较两次
      if (formData.memberType === formData.subMemberType){
        // 先清除两个的校验告警提示，目的是清除另一个密码的不一致的提示
        this.$refs['form'].clearValidate(['memberType','subMemberType']);
        callback();
      }else{
        callback(new Error('用户类型选择不一致，请确认正确的用户类型!'));
      }
    }
    return {
      showLayer: false,
      form: {
        memberType: '',
        subMemberType: '',
        proposerName: ''
      },
      rules: {
        memberType: [
          {required: true, message: '请选择用户类型!', trigger: 'change'},
          {required: true, validator: comparePasswdValidator, trigger: 'change','getValuesMethod': this.getValuesMethod}
        ],
        subMemberType: [
          {required: true, message: '请确认用户类型!', trigger: 'change'},
          {required: true, validator: comparePasswdValidator, trigger: 'change','getValuesMethod': this.getValuesMethod}
        ],
        proposerName: [
          {required: true, message: '请完善机构/企业 名称!', trigger: 'input'},
        ]
      }
    }
  },
  watch:{
    '$store.state.user'(){
      this.getUser()
    }
  },
  mounted() {
    /*console.log(this.user)
    if(this.$store.state.user){
      // console.log(1111111111,this.user)
      console.log(1111111111,this.$store.state.user)
    }*/
  },
  methods: {
    // 获取值的方法，为所有需要多字段联合校验的校验器使用
    getValuesMethod(){
      return this.form;
    },

    getUser(){
      //用户是否在三天前注册
      let isMoreDay = moment.now() > ((this.$store.state.user.uc_registerTime+3600*24*3)*1000);
      let uc_memberType = this.$store.state.user.uc_memberType;
      console.log(isMoreDay)
      if(isMoreDay && uc_memberType == 0){
        this.showLayer = true;
      }
    },
    submitType(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            memberType: this.form.memberType,
            proposerName: this.form.proposerName
          }
          console.log(data)
          this.$axios.post("/api/Member/setIdentity/", data).then(res => {
            console.log(res)
            if(res.data.code === 0){
              this.$message.success("提交成功!");
              this.showLayer = false;
            } else {
              this.$message.error(res.data.msg);
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    }
  }
}
